@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'DM Sans', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.2px;
  }
}
input.defaultCheckbox {
  color: white;
}

input.defaultCheckbox::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A");
  fill: currentColor;
  opacity: 0;
  height: 16px;
  width: 16px;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input.defaultCheckbox::before path {
  fill: currentColor;
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.rdt_TableCol_Sortable {
  color: var(--color-gray-600);
  text-transform: uppercase;
  font-weight: bold;
}

.rdt_Pagination {
  color: var(--color-gray-600) !important;
}

.rdt_TableCol_Sortable:hover,
.rdt_TableCol_Sortable:hover span {
  opacity: 1 !important;
}

.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination {
  background-color: inherit !important;
}

.chakra-modal__overlay,
.chakra-modal__content-container {
  z-index: 100;
}

.rdt_TableCell:first-of-type,
.rdt_TableCol:first-of-type {
  padding-left: 0;
}

.rdt_TableCell:last-of-type,
.rdt_TableCol:last-of-type {
  padding-right: 0;
}

.rdt_Pagination {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

html {
  scrollbar-color: #a3aed0 white;
  scrollbar-width: auto;
}

:focus {
  outline: none;
}

.fc-daygrid-day {
  cursor: pointer;
}

.chakra-portal div {
  z-index: 1000000;
}

.fc-daygrid-dot-event {
  background: none !important;
  height: auto !important;
  padding: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background: rgb(244 247 254) !important;
  .fc-daygrid-day-number {
    font-weight: 700;
  }
}

.tox .tox-edit-area::before {
  border: none !important;
}

.tox-tbtn,
.tox-tbtn span {
  cursor: pointer !important;
}

.tox-tinymce {
  border: 1px solid #dadeec !important;
}
